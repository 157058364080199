import { SiteItemData, SiteVideoData } from "../../../../interfaces";
import { SiteVideoInline } from "../../../../siteWidgets/videoPlayer/genericInlineVideoPlayer/siteVideoInline";
import { FrontCopyButton } from "../../../../commonComponents/inline/frontCopyButton/frontCopyButton";
import { HyperLinkBuilder } from "../../../../commonComponents/inline/hyperLink";
import { openFacebookShare, openTwitterShare } from "../../../../widgetsUtils";
import { WhatsAppShareButton } from "../../../../siteWidgets/socialShareButtons/whatsApp/whatsAppShareButton";

interface VideoVerticalFeedItemProps {
    videoItem: SiteItemData & { hidePrerollAds?: boolean, prerollIndex?: number }
    feedActiveSlide: number
    domain: string
    index: number
    showPrerollAds: boolean
    onNextSlide: () => void
    onCloseVideoGalleryView: () => void
}

interface VideoVerticalFeedItemState {
    isSubtitleOpen: boolean
    isMediaShareVisible: boolean
    dynamicHeight: number;
}
export class VideoVerticalFeedItem extends React.Component<VideoVerticalFeedItemProps, VideoVerticalFeedItemState>{
    private resizeObserver: IntersectionObserver | null = null;
    constructor(props) {
        super(props);
        this.state = {
            isSubtitleOpen: false,
            isMediaShareVisible: false,
            dynamicHeight: window.innerHeight,
        }
    }

    componentDidMount() {
        this.initializeEndedEventListeners();
        document.addEventListener('click', this.handleClickOutside);

        this.updateDynamicHeight();
        this.initializeResizeObserver();
        window.addEventListener("resize", this.updateDynamicHeight);
    }

    componentWillUnmount() {
        this.removeEndedEventListeners();
        document.removeEventListener('click', this.handleClickOutside);

        this.cleanupResizeObserver();
        window.removeEventListener("resize", this.updateDynamicHeight);
    }

    private initializeResizeObserver() {
        this.resizeObserver = new IntersectionObserver(() => {
            this.updateDynamicHeight();
        });

        const target = document.body;
        if (target && this.resizeObserver) {
            this.resizeObserver.observe(target);
        }
    }

    private cleanupResizeObserver() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
            this.resizeObserver = null;
        }
    }

    private updateDynamicHeight = () => {
        const viewportHeight = window.innerHeight;

        const mainNavElement = document.querySelector(".BottomHeaderArea.sticky .mainNav") as HTMLElement;
        const adsSargelElement = document.getElementById("ads.sargel");
        const adsBannerElement = document.getElementById("ads.banner");

        const mainNavHeight = mainNavElement ? mainNavElement.offsetHeight : 0;
        const adsSargelHeight = adsSargelElement ? adsSargelElement.offsetHeight : 0;
        const adsBannerHeight = adsBannerElement ? adsBannerElement.offsetHeight : 0;

        const dynamicHeight = viewportHeight - mainNavHeight - adsSargelHeight - adsBannerHeight;

        this.setState({ dynamicHeight });
    };

    private handleClickOutside = (event: MouseEvent) => {
        if ((event.target as Element).className.includes("slick-slide")) this.props.onCloseVideoGalleryView();
    };

    private initializeEndedEventListeners() {
        setTimeout(() => {
            const videoElements = document.querySelectorAll(".videoGalleryVerticalComponenta .feedDisplay .slick-slide .videoVerticalFeedItem #player .fp-engine") as NodeListOf<HTMLVideoElement>;
            if (videoElements.length) {
                videoElements.forEach((element) => {
                    element.addEventListener("ended", this.handleVideoEnded);
                })
            };
        }, 600)
    }

    private removeEndedEventListeners() {
        const videoElements = document.querySelectorAll(".videoGalleryVerticalComponenta .feedDisplay .slick-slide .videoVerticalFeedItem #player .fp-engine") as NodeListOf<HTMLVideoElement>;
        if (videoElements.length) {
            videoElements.forEach((element) => {
                element.removeEventListener("ended", this.handleVideoEnded)
            })
        };
    }

    private handleVideoEnded = (e) => {
        e.target.currentTime = 0;
        e.target.click();
        this.props.onNextSlide();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.feedActiveSlide !== this.props.feedActiveSlide) {
            setTimeout(() => {
                const videoElement = document.querySelector(".videoGalleryVerticalComponenta .feedDisplay .slick-active .videoVerticalFeedItem #player.is-paused .fp-engine") as HTMLVideoElement;
                const unActiveVideoElement = document.querySelector(".videoGalleryVerticalComponenta .feedDisplay .slick-slide:not(.slick-active) .videoVerticalFeedItem #player.is-playing .fp-engine") as HTMLVideoElement;
                if (videoElement) {
                    setTimeout(() => {
                        if (videoElement.paused) {
                            videoElement.pause();
                            videoElement.currentTime = 0;
                            videoElement.play();
                        }
                    }, 500)
                };
                if (unActiveVideoElement) unActiveVideoElement.click();
            }, 600)
        }
    }

    private onSubtitleModeToggle = () => {
        this.setState({ isSubtitleOpen: !this.state.isSubtitleOpen });
    }

    private onMediaShareToggle = () => {
        this.setState({ isMediaShareVisible: !this.state.isMediaShareVisible });
    }

    private handleShareBtnClicked = (button: string) => {
        this.setState({ isMediaShareVisible: false });
        const { domain, videoItem } = this.props;

        if (typeof window !== "undefined") {
            const baseUrl = `${domain}/video/${(videoItem.media as SiteVideoData).videoPageId}`;

            switch (button) {
                case "fb":
                    openFacebookShare(baseUrl, "", () => { }, "vertical_video")
                    break;
                case "tw":
                    openTwitterShare(baseUrl, () => { }, "vertical_video")
                    break;
                case "wa":
                    WhatsAppShareButton.openWhatsAppShare(baseUrl, () => { }, "vertical_video")
                    break;
            }
        }
    }

    private pushGA4Event = () => {
        if (typeof window == "undefined" || !window.dataLayer) return;
        const { index } = this.props;

        window.dataLayer.push({
            event: "content_click",
            componenta_name: "vertical_video",
            content_type: "componenta",
            position_in_component: index,
            click_text: "לכתבה המלאה"
        });
    };

    public render() {
        const { isSubtitleOpen, isMediaShareVisible, dynamicHeight } = this.state;
        const { videoItem, domain, showPrerollAds } = this.props;
        const { media, title, subTitle, titleLink, titleTargetLink } = videoItem;
        const subtitleClass = `videoSubtitle ${isSubtitleOpen ? "" : "close"}`;
        const videoPageId = (videoItem.media as SiteVideoData).videoPageId;
        const videoPageShareUrl = `${domain}/video/${videoPageId}?utm_source=ynet.co.il&utm_medium=share&utm_campaign=copy_url&utm_content=vertical_video`;

        return (
            <div className="videoVerticalFeedItemWrapper" style={{ height: dynamicHeight, width: dynamicHeight * 0.56 }}>
                <div className="videoVerticalFeedItem" style={{ height: dynamicHeight, width: dynamicHeight * 0.56 }}>
                    {videoPageId &&
                        <div className="shareArea">
                            <div className="shareBtn" onClick={this.onMediaShareToggle}></div>
                            {isMediaShareVisible &&
                                <div className="mediaShareWrapper">
                                    <div className="mediaShareItem" onClick={e => this.handleShareBtnClicked("fb")}>
                                        <div className="mediaShareIcon facebook"></div>
                                        <div className="mediaShareText">Facebook</div>
                                    </div>
                                    <div className="mediaShareItem" onClick={e => this.handleShareBtnClicked("wa")}>
                                        <div className="mediaShareIcon whatsapp"></div>
                                        <div className="mediaShareText">WhatsApp</div>
                                    </div>
                                    <div className="mediaShareItem" onClick={e => this.handleShareBtnClicked("tw")}>
                                        <div className="mediaShareIcon twitter"></div>
                                        <div className="mediaShareText">Twitter</div>
                                    </div>
                                    <div className="mediaShareItem">
                                        <FrontCopyButton popupText="הקישור הועתק ללוח" title={title} url={videoPageShareUrl} />
                                        <div className="mediaShareText">העתקת קישור</div>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    <SiteVideoInline
                        data={media as SiteVideoData}
                        applyStickyness={true}
                        width={dynamicHeight * 0.56}
                        height={dynamicHeight}
                        hideVideoAds={showPrerollAds ? videoItem.hidePrerollAds : true}
                        isShareButton={true}
                        adIndex={videoItem.prerollIndex}
                        runArticleAutoPlayLogic={false} />
                    <div className="contentDiv">
                        <div className="videoTitle" dangerouslySetInnerHTML={{ __html: title }} />
                        <div className={subtitleClass} onClick={this.onSubtitleModeToggle} dangerouslySetInnerHTML={{ __html: subTitle }} />
                        {titleLink &&
                            <div onClick={this.pushGA4Event}>
                                <HyperLinkBuilder
                                    className="videoLink"
                                    href={titleLink}
                                    linkTarget={titleTargetLink}
                                >
                                    לכתבה המלאה
                        </HyperLinkBuilder>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}