import * as ReactDOM from "react-dom"
//-- start imports
import { SiteMediaCarousel } from "../siteWidgets/siteMediaCarousel"
import { PlainGallery } from "./gallery/components/plainGallery"
import { YnetSearch } from "./ynetSearch/components/ynetSearch/ynetSearch";
import { SiteArticleComments } from "../siteWidgets/SiteArticleComments"
import { SiteArticleImagesGallery } from "./article/componentas/articleImagesGallery/SiteArticleImagesGallery"
import { MultiImagesFront } from "./multiImages/components/multiImagesFront"
import { SiteScrollToTopButton } from "../siteWidgets/scrollToTopButton/SiteScrollToTopButton"
import { SiteTickerPopUp } from "./ticker/components/tickerPopUp"
import { SiteArticleSideShare } from "./article/componentas/articleSideShareComponenta/articleSideShareComponent"
import { SiteArticleBottomShare } from "./article/componentas/articleBottomShareComponenta/articleBottomShareComponent"
import { SiteBreakingNewsComponenta } from "./breakingNews/components/SiteBreakingNewsComponenta"
import { SiteTickerAutoComponenta } from "./tickerAuto/components/siteTickerAutoComponenta"
import { MultiImagesLabelsFront } from "./multiImagesWithLabels/components/multiImagesLabelsFront"
import { SiteArticleHeaderShare } from "./article/componentas/articleHeaderComponenta/articleHeaderShareComponenta";
import { SiteArticleHeaderMobileShare } from "./article/componentas/articleHeaderComponenta/SiteArticleHeaderMobileShare";
import { MultiImagesCommertialFront } from "./multiImagesCommertial/components/multiImagesFront"
import { MultiImagesSpecialFront } from "./multiImagesSpecial/components/multiImagesSpecialFront"
import { MultiImagesWithFrameFront } from "./multiImagesWithIframe/components/multiImagesWithFrameFront"
import { GroupsSite } from "./multiArticleRotation/components/groupsSite"
import { YnetTvSiteMedia, SiteVideoMedia, siteScriptName } from "../siteWidgets/siteMedia"
import { CityList } from "./myNet/components/CityList"
import { MultiImagesLabelsFront2 } from "./multImagesLabels2/components/multiImagesLabelsFront"
import { YnetNewsSearch } from "./ynetNewsSearch/components/ynetSearch/ynetNewsSearch";
import { MobileHeaderComponenta } from "./ynetNewsHeader/components/mobileHeader";
import { SiteTickerMobileAutoComponenta } from "./englishTicker/siteMobileTickerComponentaInside";
import { PikudHaOrefAlerts } from "../siteWidgets/PikudHaorefAlerts/pikudHaOrefAlerts"
import { SiteInfinityHeadlinesComponenta } from "./infinityHeadlines/components/siteInfinityHeadlinesComponenta";
import { TaboolaComponenta } from "./taboola/taboolaComponenta";
import { FrontGalleryComponenta } from "./mediaGallery/components/frontMediaGallery";
import { MediaGalleryMobile } from "./mediaGallery/components/mediaGalleryMobile";
import { InfinityArticleComponenta } from "./InfinityArticle/components/infinityArticleComponenta"
import { SiteLinkLightBoxInjector } from "../../front/staticServerRendering/LightboxInjector/SiteLightBoxInjector/SiteLightBoxInjector"
import { PplusHeaderFront } from "./pplus/pplusHeader/components/pplusFrontHeader";
import { PplusPlainGallery } from "./pplus/pplusGallery/components/plainGallery";
import { PremiumArticleHeaderShareButtons } from "./article/componentas/premium/componentas/premiumArticleHeaderComponenta/premiumArticleHeaderShareButtons"
import { FrontYnetPlusHeader } from "./ynetPlusHeader/components/headerFront";
import { YplusStickyMenu } from "./ynetPlusHeader/components/menu";
import { SiteVideoInline } from "../siteWidgets/videoPlayer/genericInlineVideoPlayer/siteVideoInline"
import { SiteYplusSubChannelMenu } from "./yPlusSubChannelHeader/components/subChannelHeader"
import { SiteYplusLoadMoreComponenta } from "./yPlusLoadMore/components/yplusLoadMore"
import { ImageSlider } from "../commonComponents/inline/imageSlider/imageSlider";
import { YnetPremiumLoginMobile } from "./article/componentas/premium/componentas/ynetPremiumLogin/ynetPremiumLoginMobile";
import { MobileYnetPlusHeader } from "./ynetPlusHeader/components/ynetPlusMobileHeader";
import { SiteYnetFooter } from "./ynetFooter/components/ynetFooter";
import { SitePremiumArticleTitleImageComponenta } from "./article/componentas/premium/componentas/premiumArticleTitleImageComponenta/sitePremiumArticleTitleImageComponenta";
import { SiteMynetHeaderComponenta } from "./mynetWidgets/header/components/siteMynetHeaderComponenta";
import { SpotimScriptInjector } from "./article/componentas/articleComments/spotimScriptInjector"
import { SiteTickerPopUpMynet } from "./mynetWidgets/ticker/components/tickerPopUp";
import { YplusSubChannelMenuMobileSite } from "./yPlusSubChannelHeader/components/mobileSubChannelHeader";
import { SiteYplusSearchResultsWidgetComponenta } from "./yplusSearchResults/componentas/SiteYplusSearchResultsWidgetComponenta"
import { MobileTickerManualView } from "./ticker/components/mobileTickerManualView";
import { CalcalistShareMenu } from "../commonComponents/base/CalcalistShareMenu";
import { CalcalistArticleActions } from "./calcalist/components/calcalistArticleActions"
import { SiteMynetHeaderMobileComponenta } from "./mynetWidgets/header/components/mobileHeader/siteMynetHeaderMobileComponenta";
import { SiteMynetArticleSideShare } from "./mynetWidgets/articleSocialShareWidget/componentas/SiteMynetArticleSideShare"
import { CommertialFrontComponenta } from "./calcalistWidgets/commercial/components/commertialFrontComponenta";
import { SiteArticleHeadlinesComponenta } from "./calcalistWidgets/articleHeadlines/components/calcalistLoadMore";
import { SiteCalcalistBuzzComponenta } from "./calcalistWidgets/buzz/componentas/siteCalcalistBuzzComponenta"
import { CalcalistSpecialContentFront } from "./calcalistWidgets/specialContent/components/calcalistSpecialContentFront"
import { MultiImagesMobile } from "./mynetWidgets/multiImages/multiImageMobile";
import { LeftSideMenuComponenta } from "./calcalistWidgets/categoryHeader/components/leftSideMenuComponenta"
import { SiteArticleSocialShare1280 } from "./article/componentas/articleSocialShare1280Component/siteArticleSocialShare1280"
import { TickerList } from "./mynetWidgets/ticker/components/flashList";
import { SiteMynetFooter } from "./mynetWidgets/footer/components/SiteMynetFooterComponenta"
import { TickerItemSlotWar } from "./newYnetWidgets/tickerWar/components/tickerItemSlot";
import { CalcalistFooter } from "./calcalistWidgets/footer/components/footerCalcalistContent"
import { MultiImagesLabelsGallery } from "./newYnetWidgets/multiImagesGallery/components/multiImagesLabelsFront";
import { SiteArticleSocialBottomShare1280 } from "./article/componentas/articleBottomShareComponenta/siteArticleSocialBottomShare1280"
import { YnetFlashListComponenta } from "./calcalistWidgets/ynetFlashList/components/ynetFlashList";
import { MultiImagesRowsFront } from "./mynetWidgets/multiImagesMultiRows/components/multiImagesRowsFront"
import { SiteStockLinksInjector } from "../../front/staticServerRendering/stockLinksInjector/SiteStockLinksInjector";
import { CalcalistCarouselComponenta } from "./calcalistWidgets/articleCarousel/components/carousel"
import { CalcalistLoginAndShare } from "./calcalistWidgets/calcalistLoginArea/calcalistLoginComponenta"
import { HotTagsFrontComponenta } from "./newYnetWidgets/hotTagsComponenta/hotTagsFront";
import { SiteArticleVideoComponentaShareButtons } from "./articleVideo/siteArticleVideoComponentaShareButtons"
import { PlainGallery1280 } from "./newYnetWidgets/gallery/components/plainGallery"
import { WeatherCitySelectorRow } from "./weather/components/citySelectorDropdown/WeatherCitySelector"
import { FrontSideVideoCalcalist } from "./calcalistWidgets/sideVideoGallery/components/frontSideVideoComponenta";
import { FrontTvCalcalist } from "./calcalistWidgets/calcalistTv/components/calcalistTvFront";
import { TaboolaComponentaGrid1280 } from "./taboola/taboolaComponentaGrid1280";
import { WeatherMapExtendedTab } from "./weatherMapYnet/components/WeatherMapExtendedTab/WeatherMapExtendedTab";
import { SiteArticlePremiumCutter } from "../../textEditor/components/premiumFlag/siteArticlePremiumCutter";
import { YnetShopsGallery } from "./newYnetWidgets/ynetShops/component/ynetShopsGalleryComponenta"
import { CalcalistStockRecommendationPoints } from "./calcalist/components/calcalistArticleStockRecommend/components/calcalistStockRecommendationPoints";
import { MobileLaishaHeader } from "./laishaWidgets/header/components/mobileLaishaHeaderComponenta";
import { TagsSearchComponenta } from "./tagPageMain/tagsSearchComponenta"
import { ArticleHotUpdateComponent } from "./article/componentas/articleHotUpdate/components/articleHotUpdateComponent";
import { insertDateInSiteServerRender } from "../siteWidgets/formatDateDisplay"
import { StripMarketingFront } from "./ynet1280/marketingStrip/components/frontStripMarketingComponenta";
import { SiteTickerAutoRowComponenta } from "./ynet1280/autoTickerRowWidget/components/siteAutoTickerRowComponenta";
import { FrontVideoGallery } from "./ynet1280/ynetTv/components/frontYnetVideo";
import { MultiImagesMagazineFront } from "./ynet1280/magazineMultiImages/components/multiMagazineFront";
import { ElectionDateCounter } from "./newYnetWidgets/election/components/electionDateCounter";
import { Gallery1280 } from "./ynet1280/galleryWidget/components/plainGallery"
import { SiteArticleSocialShareNew1280 } from "./article/componentas/articleSocialShare1280Component/articleSocialShareNew1280";
import { BlogsAutoFeedComponent } from "./blogsAutoFeed/components/blogsAutoFeedComponent";
import { SiteYnetHeaderComponenta } from "./ynet1280/ynetHeader/components/siteYnetHeaderComponenta"
import { MultiImages1280Front } from "./ynet1280/multiImages/components/mulitImagesFront";
import { YnetPlusPromotion } from "./ynet1280/multiImagesYplus/components/promotionYplus"
import { SiteArticleContact } from "./ynet1280/articleConnectUs/siteArticleContact";
import { Accordion } from "../siteWidgets/accordion/accordion";
import { YnetShops1280Gallery } from "./ynet1280/ynetShops/components/ynetShops1280Gallery";
import { ArticleImageSlider } from "./calcalistWidgets/articleImageGallery/components/articleImageSlider";
import { ArticleImagePriceTagPointsComponent } from "./articleImageTagPrice/component/articleImagePriceTagPointsComponent"
import { CommertialTeaserFront } from "./calcalistWidgets/commericalTeaser/components/commertialTeaserFront"
import { AppArticleContact } from "./ynet1280/articleConnectUs/appArticleContact";
import { SiteArticleBottomShareCtech } from "./ctechWidgets/ctechBottomShareButtons/articleBottomshareButtonsCtech";
import { SiteCategoryHeaderComponenta } from "./calcalistWidgets/categoryHeader/components/siteCategoryHeaderComponenta"
import { CtechHeaderMenu } from "./ctechWidgets/ctechHeader/components/ctechHeaderMenu"
import { WeatherMultiArticleItem } from "./ynet1280/multiArticleWidget/components/weatherMultiArticleItem";
import { SiteMobileCtechHeaderComponenta } from "./ctechWidgets/ctechHeader/components/siteMobileCtechHeaderComponenta"
import { OneVideoGallery } from "./ynet1280/oneVideo/oneVideoGallery";
import { SiteCtechWideBuzzComponenta } from "./ctechWidgets/ctechWideBuzz/components/siteCtechWideBuzz"
import { PplusMobileHeader } from "./pplus/pplusHeader/components/pplusMobileHeader";
import { PplusShareMenu } from "./pplus/pplusShareMenu/pplusShareMenu";
import { SiteCtechTwentyFourSevenWideComponenta } from "./ctechWidgets/ctechTwentyFourSevenWide/components/siteCtechTwentyFourSevenWideComponenta"
import { MobileArticleImagesGallery } from "./article/componentas/articleImagesGallery/mobileArticleImagesGallery";
import { SitePplusLoadMoreComponenta } from "./pplus/pplusLoadMore/components/pplusLoadMore";
import { CartoonArchiveComponenta } from "./calcalistWidgets/cartoonArchive/components/cartoonArchiveComponenta"
import { MobileYPlusTopStory } from "./ynetPlusHeader/components/mobileYplusTopStory";
import { YplusMultiImagesFront } from "./yplusMultiImages/components/yplusMultiImagesFront";
import { ArticleMobileHeaderShareComponent } from "./article/componentas/articleHeaderComponenta/articleMobileHeaderShareComopnent";
import { SiteMediaMobileCarousel } from "../siteWidgets/siteMobileMediaCarousel";
import { MutamMarketingSign } from "../commonComponents/inline/marketingSign/mutamMarketingSign";
import { SiteMutamYnetHeaderComponenta } from "./ynet1280/ynetHeader/components/mutamComponents/siteMutamYnetHeaderComponenta";
import { SiteArticleVerticalSocialShare } from "./article/componentas/articleVerticalSocialShareComponent/siteArticleVerticalSocialShare";
import { RedMailSiteComponentaWrapper } from "./redMail/components/redMailSiteComponentaWrapper"
import { NewsLetterSignUpPopUp } from "./newsLetter/components/newsLetterSignUpPopUp";
import { CalcalistPianoLoginAndShare } from "./calcalistWidgets/calcalistPianoLoginArea/calcalistPianoLoginComponenta"
import { PremiumMutamHeader } from "./article/componentas/premium/componentas/premiumMutamHeader/premiumMutamHeader";
import { PromoYplusBtn } from "./ynet1280/yPlusWithPaper/components/promoBtn"
import { SiteYnetNewsHeaderComponenta } from "./ynetnews1280/siteHeader/components/siteYnetHeaderComponenta";
import { SiteMutamYnetNewsHeaderComponenta } from "./ynetnews1280/siteHeader/components/mutamComponents/siteMutamYnetHeaderComponenta";
import { RadioHomepagePlayerComponenta } from "./radioWidgets/radioHompage/components/radioHomepagePlayerComponenta";
import { GeneralCommercialTeaserFront } from "./calcalistWidgets/generalCommercialTeaser/components/generalCommercialTeaserFront";
import { RadioScheduleComponent } from "./radioWidgets/radioSchedule/components/radioScheduleComponent";
import { PodcastPlayer } from "./radioWidgets/floatingPlayer/podcastPlayerButton";
import { RadioNewsflashPlayer } from "./radioWidgets/audioNewsflash/radioNewsflashPlayer";
import { SiteArticleRecipeRatingComponenta } from "./articleRecipeRating/siteArticleRecipeRatingComponenta";
import { CalcalistMobileNavigationComponenta } from "./calcalistWidgets/calcalistMobileNavigation/components/calcalistMobileNavigationComponenta";
import { IngredientsSection } from "./article/componentas/articleRecipeComponenta/components/ingredientsSection";
import { TimerComponenta } from "./article/componentas/articleRecipeTimer/components/timerComponenta"
import { SiteVideoArchiveComponenta } from "./calcalistWidgets/videoArchive/components/siteVideoArchiveComponenta";
import { SiteAnalyticsInjector } from "../../front/staticServerRendering/AnalyticsInjector/siteAnalyticsInjector"
import { CalcalistNewsLetterComponenta } from "./calcalistWidgets/calcalistNewsLetter/components/calcalistNewsLetterComponenta";
import { FloatingVideoLivePlayer } from "../siteWidgets/videoPlayer/genericInlineVideoPlayer/floatingVideoLivePlayer/floatingVideoLivePlayer"
import { AppRadioArticleLivePlayer } from "./radioWidgets/radioArticleLivePlayer/components/appRadioArticleLivePlayer";
import { SitePhotoArchiveComponenta } from "./calcalistWidgets/photoArchive/components/sitePhotoArchiveComponenta";
import { GoogleSearchComponenta } from "./googleSearch/components/googleSearchComponenta";
import { AudioPodcastPlayer } from "./radioWidgets/articleAudioPodcast/components/audioPodcastPlayer"
import { TwentyFourSeven1280Componenta } from "./ynet1280/twentyFourSeven/components/twentyFourSeven1280Componenta";
import { CalcalistPodcastShareMenu } from "./article/componentas/calcalistArticlePodcastTopStoryCoponenta/components/calcalistPodcastShareMenu"
import { PodcastCategoryComponenta } from "./calcalistWidgets/podcastCategory/components/podcastCategoryComponenta";
import { SiteMusafArchiveComponenta } from "./calcalistWidgets/musafArchive/siteMusafArchiveComponenta";
import { ArticleNewsletterWrapper } from "./articleNewsletter/components/articleNewsletterWrapper";
import { SignUpForPodcast } from "./radioWidgets/articleAudioPodcast/components/signupForPodcast"
import { PodcastArchiveComponenta } from "./calcalistWidgets/podcastArchive/components/podcastArchiveComponenta";
import { CalcalistDetachedPlayer } from "./calcalistWidgets/calcalistPodcastPopupPlayer/calcalistDetachedPlayer"
import { CalcalistPodcastPlayer } from "./calcalistWidgets/calcalistPodcastPopupPlayer/calcalistPodcastPlayer"
import { PodcastPlayerControlsComponent } from "./article/componentas/calcalistArticlePodcastTopStoryCoponenta/components/podcastPlayerControlsComponent";
import { YplusAccessibiltyButton } from "./ynetPlusFooter/components/yplusAccessibiltyButton";
import { NewsFlashAndTwentyFourSevenMobileComponenta } from "./ynet1280/newsflashAndTwentyFourSeven/components/newsFlashAndTwentyFourSevenMobileComponenta";
import { YplusNotStickyMenu } from "./ynetPlusHeader/components/nonStickyNavList";
import { YnetLiveComponenta } from "./ynet1280/ynetLive/components/ynetLiveComponenta";
import { TickerAutoDesktopView } from "./ynet1280/newsflashAndTwentyFourSeven/components/autoTickerDesktopView"
import { LiveVideoPlayerMutamComponenta } from "./ynet1280/liveVideoPlayerMutam/components/liveVideoPlayerMutamComponenta";
import { BlogPostsSorter } from "./article/componentas/articleBlog/components/blogPostsSorter";
import { ArticleBlogsReadMoreHandler } from "../../front/staticServerRendering/articleBlogsReadMoreHandler/articleBlogsReadMoreHandler";
import { SiteMobileCalcalistBuzzComponenta } from "./calcalistWidgets/buzz/componentas/siteMobileCalcalistBuzzComponenta";
import { CommertialFrontComponentaMutam } from "./calcalistWidgets/commercial/components/commercialFrontComponentaMutam";
import { CalcalistMutamShareMenu } from "./calcalistWidgets/generalMutamComps/calcalistMutamShareMenu";
import { CalcalistNewsLetterNativeComponenta } from "./calcalistWidgets/calcalistNewsLetterNative/components/calcalistNewsLetterNativeComponenta";
import { BlogsSpotimScriptInjector } from "./article/componentas/articleComments/blogsSpotimScriptInjector";
import { HotArticleSlotListMutam } from "./calcalistWidgets/hotArticles/components/hotArticleSlotListMutam";
import { VideoGalleryVerticalComponenta } from "./ynet1280/videoGalleryVerticalWidget/components/videoGalleryVerticalComponenta";
import { StockPopUp } from "../../front/staticServerRendering/stockPopUp";
import { VideoGalleryVerticalMutamComponenta } from "./ynet1280/videoGalleryVerticalWidget/components/videoGalleryVerticalMutamComponenta";
import { CommertialTeaserMobile } from "./calcalistWidgets/commericalTeaser/components/commertialTeaserMobile"
import { VideoPageMain } from "./ynet1280/videoPageMain/components/videoMainPage";
import { VideoMainPageMediaShare } from "./ynet1280/videoPageMain/components/videoMainPageMediaShare";
import { ArticleMutamHeaderShareComponent } from "./calcalist/components/articleMutamHeaderShareComponent";
import { StockPopUpMobile } from "../../front/staticServerRendering/stockPopUpMobile";
import { CalcalistSpecialContentMobile } from "./calcalistWidgets/specialContent/components/calcalistSpecialContentMobile"
import { ArticleImageGalleryMutamCalcalist } from "./calcalistWidgets/articleImageGallery/components/calcalistArticleGalleryMobile"
import { CaricaturistMobileComponenta } from "./calcalistWidgets/caricaturist/components/caricaturistMobileComponenta"
import { MobileTvCalcalist } from "./calcalistWidgets/calcalistTv/components/calcalistTvMobile"
import { CalcalistWideBuzzMobileComponenta } from "./calcalistWidgets/calcalistWideBuzz/components/calcalistWideBuzzMobileComponenta"
import { CartoonArchiveComponentaMobile } from "./calcalistWidgets/cartoonArchive/components/cartoonArchiveComponentaMobile"
import { SiteMediaMobileWrapper } from "../siteWidgets/siteMediaMutamWrapper";
import { CalcalistWidePhotoMobileComponenta } from "./calcalistWidgets/calcalistWidePhoto/components/calcalistWidePhotoMobileComponenta";
import { CategoryHeaderMutam } from "./calcalistWidgets/categoryHeader/components/mutamHeader/categoryHeaderMutam";
import { CalcalistTwentyFourSevenWideMobile } from "./calcalistWidgets/calcalistTwentyFourSevenWide/components/calcalistTwentyFourSevenWideMobile";
import { RadionasVideoGalleryVerticalComponenta } from "./radionas/verticalVideoGallery/components/radionasVideoGalleryVerticalComponenta";
import { RadionasVideoGalleryVerticalMutamComponenta } from "./radionas/verticalVideoGallery/components/radionasVideoGalleryVerticalMutamComponenta";
import { SiteRadionasHeaderComponenta } from "./radionas/radionasHeader/components/siteRadionasHeaderComponenta";
import { SiteMutamRadionasHeaderComponenta } from "./radionas/radionasHeader/components/siteMutamRadionasHeaderComponenta";
import { VideoGalleryVerticalAutoComponenta } from "./radionas/verticalVideoGalleryAuto/components/videoGalleryVerticalAutoComponenta";
import { VideoGalleryVerticalAutoMutamComponenta } from "./radionas/verticalVideoGalleryAuto/components/videoGalleryVerticalAutoMutamComponenta";
import { MagazineGalleryImages } from "./radionas/magazineGallery/components/magazineGalleryImages";
import { RadionasVideoLiveMutamComponenta } from "./radionas/live/components/radionasVideoliveMutam";
import { ArticleMagazineChapterMenu } from "./article/articleMagazineChapterMenu/articleMagazineChapterMenu";
import { RadionasAudioPlayer } from "./radionas/audioPlayer/radionasAudioPlayer";
import { NasRadioScheduleComponent } from "./radionas/radioSchedule/components/nasRadioScheduleComopnent";
import { NasRadioHomepageComponent } from "./radionas/radioHompage/components/nasRadioHomepageComponent";
import { NasRadioHomepageTeaser } from "./radionas/radioHomepageTeaser/components/nasRadioHomepageTeaser";
import { MultiImagesRadionasFront } from "./radionas/multiImages/components/multiImagesFront";
import { MultiImagesRadionasFrontMobile } from "./radionas/multiImages/components/multiImagesFrontMobile";
import { SiteArticleBlogHeaderComponenta } from "./calcalistWidgets/articleBlogHeader/components/siteArticleBlogHeaderComponenta";
import RadionasNewsFlashHomepageComponent from "./radionas/newsFlashHomepage/components/radionasNewsFlashHomepageComponent"
import { FrontCopyButton } from "../commonComponents/inline/frontCopyButton/frontCopyButton";
import { NewsFlashListWrapper } from "./radionas/newsFlash/components/newsFlashListWrapper";
import { RadionasVideoPageMain } from "./radionas/videoPageMain/components/radionasVideoMainPage";
import ArticleImageSliderGalleryComponenta from "./article/componentas/articleImageGalleryComponenta/articleImageSliderGalleryComponenta"
import { ArticleCommercialVideoPlayer } from "./calcalistWidgets/articleCommercialVideo/components/articleCommercialVideoPlayer";
import { StoriesAutoComponenta } from "./radionas/storiesAuto/components/storiesAutoComponenta";
import { StoriesAutoMobileComponenta } from "./radionas/storiesAuto/components/storiesAutoMobileComponenta";
import { GeneralCommercialTeaserMobile } from "./calcalistWidgets/generalCommercialTeaser/components/generalCommercialTeaserMobile";
import { CalcalistVideoGalleryVerticalComponenta } from "./calcalistWidgets/verticalVideoGallery/components/calcalistVideoGalleryVerticalComponenta";
import { CalcalistVideoGalleryVerticalMutamComponenta } from "./calcalistWidgets/verticalVideoGallery/components/calcalistVideoGalleryVerticalMutamComponenta";
import { SideVideoMutamSlots } from "./calcalistWidgets/sideVideoGallery/components/sideVideoMutamSlots";
import { ImageAndCaptionMutamItem } from "./calcalistWidgets/imageAndCaption/components/imageAndCaptionMutamItem";
import { YnetNewsComponenta } from "./ctechWidgets/ynetNews/components/ynetNewsComponenta";
import { MultiMagazineYplusFront } from "./ynet1280/multiImagesMagazinesYplus/components/multiMagazineYplusFront";
import { MultiImagesYplusFrontMobile } from "./ynet1280/multiImagesMagazinesYplus/components/multiImagesYplusFrontMobile";
import { DynamicVideoGalleryVerticalComponenta } from "./ynet1280/dynamicVerticalVideoGallery/components/dynamicVideoGalleryVerticalComponenta";
import { DynamicVideoGalleryVerticalMutamComponenta } from "./ynet1280/dynamicVerticalVideoGallery/components/dynamicVideoGalleryVerticalMutamComponenta";
import { ArticleStoryComponent } from "./radionas/nasArticleStory/articleStoryComponent";
import { MutamVideoItem } from "./calcalistWidgets/video/mutamVideoItem"
import { MultiArticlesSectionFront } from "./ynet1280/multiArticlesSection/components/multiArticlesSectionFront";
import { YplusWithPaperUpperSection } from "./ynet1280/yPlusWithPaper/components/yplusWithPaperComponentaUpperSection";
import { LiveVideoPlayerMutamComponentaEng } from "./ynet1280/liveVideoPlayerMutameEng/components/liveVideoPlayerMutamComponentaEng";
import { SiteMagazineHeaderComponenta } from "./magazineWidgets/magazineHeader/components/siteMagazineHeaderComponenta"
import { SiteMagazineHeaderMutamComponenta } from "./magazineWidgets/magazineHeader/components/siteMagazineHeadeMutamComponenta";
import { AccessebilityButton } from "./article/articleMagazineTopStory/renders/articleMagazineNagishButton";
import { MagazineVideoGalleryVerticalComponenta } from "./magazineWidgets/magazineVerticalVideoGallery/components/magazineVideoGalleryVerticalComponenta";
import { MagazineVideoGalleryVerticalMutamComponenta } from "./magazineWidgets/magazineVerticalVideoGallery/components/magazineVideoGalleryVerticalMutamComponenta";


//-- end imports


class YITSiteWidgets {
    private rendersIds: Set<string> = null;
    constructor() {
        if (typeof window['YITSiteWidgets'] == "object" && Array.isArray(window['YITSiteWidgets'])) {
            this.rendersIds = new Set(window['YITSiteWidgets'].map(parms => parms[0]))
            window['YITSiteWidgets'].forEach((parms: [string, string, any]) => {
                this.render(...parms);
            });
        }
        insertDateInSiteServerRender()
    }
    private renderCallbackCreator = (parentId: string) => {
        if (this.rendersIds && typeof window['YITSiteWidgetsCallbacks'] == "object" && Array.isArray(window['YITSiteWidgetsCallbacks'])) {
            return () => {
                if (this.rendersIds.has(parentId)) {
                    this.rendersIds.delete(parentId);
                    if (this.rendersIds.size === 0) {
                        console.info("renderFinnished");
                        window['YITSiteWidgetsCallbacks'].forEach(f => typeof f == "function" && f());
                        window['YITSiteWidgetsCallbacks'] = new YITSiteWidgetsCallbacks();
                    }
                }
            }
        }
        return undefined;
    }
    private render = (parentId: string, siteScriptName: string, props: any) => {
        if (props["__objectDataTypeName"]) {
            console.error("props of object %s with id %s are not a simple object", siteScriptName, parentId)
            return;
        }
        const siteScript = list[siteScriptName];
        if (typeof siteScript == "function") {
            try {
                ReactDOM.render(siteScript(props), document.getElementById(parentId), this.renderCallbackCreator(parentId));
            } catch (ex) {
                console.error(`Error rendering widget ${parentId}-${siteScriptName}`, ex);
            }
        } else {
            console.error("Cannot find component ", siteScriptName)
        }
    }

    public push = (parms: [string, string, any]) => {
        this.render(...parms);
    }
}

class YITSiteWidgetsCallbacks {
    public push = (func: () => void) => func();
}

const list = {
    //-- start list
    //-- common - components not connected to widgets section:
    [SiteAnalyticsInjector.siteScriptName]: (props) => <SiteAnalyticsInjector {...props} />,
    [SiteArticleImagesGallery.siteScriptName]: (props) => <SiteArticleImagesGallery {...props} />,
    [SiteLinkLightBoxInjector.siteScriptName]: (props) => <SiteLinkLightBoxInjector {...props} />,
    [SiteScrollToTopButton.siteScriptName]: (props) => <SiteScrollToTopButton {...props} />,
    [PikudHaOrefAlerts.siteScriptName]: (props) => <PikudHaOrefAlerts {...props} />,
    [SiteStockLinksInjector.siteScriptName]: (props) => <SiteStockLinksInjector {...props} />,
    //-- end common section
    //-- inside widgets Section:
    [SpotimScriptInjector.siteScriptName]: (props) => <SpotimScriptInjector {...props} />,
    [SiteMediaCarousel.siteScriptName]: (props) => <SiteMediaCarousel {...props} />,
    [PlainGallery.siteScriptName]: (props) => <PlainGallery {...props} />,
    [MultiImagesFront.siteScriptName]: (props) => <MultiImagesFront {...props} />,
    [MultiImagesLabelsFront.siteScriptName]: (props) => <MultiImagesLabelsFront {...props} />,
    [MultiImagesLabelsFront2.siteScriptName]: (props) => <MultiImagesLabelsFront2 {...props} />,
    [YnetSearch.siteScriptName]: (props) => <YnetSearch {...props} />,
    [SiteArticleComments.siteScriptName]: (props) => <SiteArticleComments {...props} />,
    [SiteVideoInline.siteScriptName]: (props) => <SiteVideoInline {...props} />,
    [SiteTickerPopUp.siteScriptName]: (props) => <SiteTickerPopUp {...props} />,
    [SiteTickerAutoComponenta.siteScriptName]: (props) => <SiteTickerAutoComponenta {...props} />,
    [SiteTickerAutoRowComponenta.siteScriptName]: (props) => <SiteTickerAutoRowComponenta {...props} />,
    [SiteArticleSideShare.siteScriptName]: (props) => <SiteArticleSideShare {...props} />,
    [SiteArticleBottomShare.siteScriptName]: (props) => <SiteArticleBottomShare {...props} />,
    [SiteBreakingNewsComponenta.siteScriptName]: (props) => <SiteBreakingNewsComponenta {...props} />,
    [SiteArticleHeaderShare.siteScriptName]: (props) => <SiteArticleHeaderShare {...props} />,
    [MultiImagesCommertialFront.siteScriptName]: (props) => <MultiImagesCommertialFront {...props} />,
    [MultiImagesSpecialFront.siteScriptName]: (props) => <MultiImagesSpecialFront {...props} />,
    [MultiImagesWithFrameFront.siteScriptName]: (props) => <MultiImagesWithFrameFront {...props} />,
    [GroupsSite.siteScriptName]: (props) => <GroupsSite {...props} />,
    [YnetTvSiteMedia["siteScriptName"]]: (props) => <YnetTvSiteMedia {...props} />,
    [SiteVideoMedia["siteScriptName"]]: (props) => <SiteVideoMedia {...props} />,
    [CityList.siteScriptName]: (props) => <CityList {...props} />,
    [YnetNewsSearch.siteScriptName]: (props) => <YnetNewsSearch {...props} />,
    [MobileHeaderComponenta.siteScriptName]: (props) => <MobileHeaderComponenta {...props} />,
    [SiteTickerMobileAutoComponenta.siteScriptName]: (props) => <SiteTickerMobileAutoComponenta {...props} />,
    [SiteArticleHeaderMobileShare.siteScriptName]: (props) => <SiteArticleHeaderMobileShare {...props} />,
    [SiteInfinityHeadlinesComponenta.siteScriptName]: (props) => <SiteInfinityHeadlinesComponenta {...props} />,
    [TaboolaComponenta.siteScriptName]: (props) => <TaboolaComponenta {...props} />,
    [FrontGalleryComponenta.siteScriptName]: (props) => <FrontGalleryComponenta {...props} />,
    [MediaGalleryMobile.siteScriptName]: (props) => <MediaGalleryMobile {...props} />,
    [InfinityArticleComponenta.siteScriptName]: (props) => <InfinityArticleComponenta {...props} />,
    [PremiumArticleHeaderShareButtons.siteScriptName]: (props) => <PremiumArticleHeaderShareButtons {...props} />,
    [PplusHeaderFront.siteScriptName]: (props) => <PplusHeaderFront {...props} />,
    [PplusPlainGallery.siteScriptName]: (props) => <PplusPlainGallery {...props} />,
    [FrontYnetPlusHeader.siteScriptName]: (props) => <FrontYnetPlusHeader {...props} />,
    [YplusStickyMenu.siteScriptName]: (props) => <FrontYnetPlusHeader {...props} />,
    [SitePremiumArticleTitleImageComponenta.siteScriptName]: (props) => <SitePremiumArticleTitleImageComponenta {...props} />,
    [SiteYplusSubChannelMenu.siteScriptName]: (props) => <SiteYplusSubChannelMenu {...props} />,
    [SiteYplusLoadMoreComponenta.siteScriptName]: (props) => <SiteYplusLoadMoreComponenta {...props} />,
    [ImageSlider.siteScriptName]: (props) => <ImageSlider {...props} />,
    [YnetPremiumLoginMobile.siteScriptName]: (props) => <YnetPremiumLoginMobile {...props} />,
    [MobileYnetPlusHeader.siteScriptName]: (props) => <MobileYnetPlusHeader {...props} />,
    [SiteYnetFooter.siteScriptName]: (props) => <SiteYnetFooter {...props} />,
    [SiteMynetHeaderComponenta.siteScriptName]: (props) => <SiteMynetHeaderComponenta {...props} />,
    [SiteTickerPopUpMynet.siteScriptName]: (props) => <SiteTickerPopUpMynet {...props} />,
    [YplusSubChannelMenuMobileSite.siteScriptName]: (props) => <YplusSubChannelMenuMobileSite {...props} />,
    [SiteYplusSearchResultsWidgetComponenta.siteScriptName]: (props) => <SiteYplusSearchResultsWidgetComponenta {...props} />,
    [MobileTickerManualView.siteScriptName]: (props) => <MobileTickerManualView {...props} />,
    [CalcalistShareMenu.siteScriptName]: (props) => <CalcalistShareMenu {...props} />,
    [CalcalistArticleActions.siteScriptName]: (props) => <CalcalistArticleActions {...props} />,
    [SiteMynetHeaderMobileComponenta.siteScriptName]: (props) => <SiteMynetHeaderMobileComponenta {...props} />,
    [SiteMynetArticleSideShare.siteScriptName]: (props) => <SiteMynetArticleSideShare {...props} />,
    [CommertialFrontComponenta.siteScriptName]: (props) => <CommertialFrontComponenta {...props} />,
    [CommertialFrontComponentaMutam.siteScriptName]: (props) => <CommertialFrontComponentaMutam {...props} />,
    [SiteArticleHeadlinesComponenta.siteScriptName]: (props) => <SiteArticleHeadlinesComponenta {...props} />,
    [SiteCalcalistBuzzComponenta.siteScriptName]: (props) => <SiteCalcalistBuzzComponenta {...props} />,
    [SiteMobileCalcalistBuzzComponenta.siteScriptName]: (props) => <SiteMobileCalcalistBuzzComponenta {...props} />,
    [CalcalistSpecialContentFront.siteScriptName]: (props) => <CalcalistSpecialContentFront {...props} />,
    [CalcalistSpecialContentMobile.siteScriptName]: (props) => <CalcalistSpecialContentMobile {...props} />,
    [MultiImagesMobile.siteScriptName]: (props) => <MultiImagesMobile {...props} />,
    [LeftSideMenuComponenta.siteScriptName]: (props) => <LeftSideMenuComponenta {...props} />,
    [SiteArticleSocialShare1280.siteScriptName]: (props) => <SiteArticleSocialShare1280 {...props} />,
    [TickerList.siteScriptName]: (props) => <TickerList {...props} />,
    [SiteMynetFooter.siteScriptName]: (props) => <SiteMynetFooter {...props} />,
    [TickerItemSlotWar.siteScriptName]: (props) => <TickerItemSlotWar {...props} />,
    [CalcalistFooter.siteScriptName]: (props) => <CalcalistFooter {...props} />,
    [MultiImagesLabelsGallery.siteScriptName]: (props) => <MultiImagesLabelsGallery {...props} />,
    [SiteArticleSocialBottomShare1280.siteScriptName]: (props) => <SiteArticleSocialBottomShare1280 {...props} />,
    [YnetFlashListComponenta.siteScriptName]: (props) => <YnetFlashListComponenta {...props} />,
    [MultiImagesRowsFront.siteScriptName]: (props) => <MultiImagesRowsFront {...props} />,
    [CalcalistCarouselComponenta.siteScriptName]: (props) => <CalcalistCarouselComponenta {...props} />,
    [CalcalistLoginAndShare.siteScriptName]: (props) => <CalcalistLoginAndShare {...props} />,
    [HotTagsFrontComponenta.siteScriptName]: (props) => <HotTagsFrontComponenta {...props} />,
    [SiteArticleVideoComponentaShareButtons.siteScriptName]: (props) => <SiteArticleVideoComponentaShareButtons {...props} />,
    [PlainGallery1280.siteScriptName]: (props) => <PlainGallery1280 {...props} />,
    [WeatherCitySelectorRow.siteScriptName]: (props) => <WeatherCitySelectorRow {...props} />,
    [FrontSideVideoCalcalist.siteScriptName]: (props) => <FrontSideVideoCalcalist {...props} />,
    [FrontTvCalcalist.siteScriptName]: (props) => <FrontTvCalcalist {...props} />,
    [MobileTvCalcalist.siteScriptName]: (props) => <MobileTvCalcalist {...props} />,
    [TaboolaComponentaGrid1280.siteScriptName]: (props) => <TaboolaComponentaGrid1280 {...props} />,
    [WeatherMapExtendedTab.siteScriptName]: (props) => <WeatherMapExtendedTab {...props} />,
    [SiteArticlePremiumCutter.siteScriptName]: (props) => <SiteArticlePremiumCutter {...props} />,
    [YnetShopsGallery.siteScriptName]: (props) => <YnetShopsGallery {...props} />,
    [CalcalistStockRecommendationPoints.siteScriptName]: (props) => <CalcalistStockRecommendationPoints {...props} />,
    [MobileLaishaHeader.siteScriptName]: (props) => <MobileLaishaHeader {...props} />,
    [TagsSearchComponenta.siteScriptName]: (props) => <TagsSearchComponenta {...props} />,
    [ArticleHotUpdateComponent.siteScriptName]: (props) => <ArticleHotUpdateComponent {...props} />,
    [StripMarketingFront.siteScriptName]: (props) => <StripMarketingFront {...props} />,
    [FrontVideoGallery.siteScriptName]: (props) => <FrontVideoGallery {...props} />,
    [MultiImagesMagazineFront.siteScriptName]: (props) => <MultiImagesMagazineFront {...props} />,
    [ElectionDateCounter.siteScriptName]: (props) => <ElectionDateCounter {...props} />,
    [Gallery1280.siteScriptName]: (props) => <Gallery1280 {...props} />,
    [SiteArticleSocialShareNew1280.siteScriptName]: (props) => <SiteArticleSocialShareNew1280 {...props} />,
    [ArticleHotUpdateComponent.siteScriptName]: (props) => <ArticleHotUpdateComponent {...props} />,
    [BlogsAutoFeedComponent.siteScriptName]: (props) => <BlogsAutoFeedComponent {...props} />,
    [SiteYnetHeaderComponenta.siteScriptName]: (props) => <SiteYnetHeaderComponenta {...props} />,
    [MultiImages1280Front.siteScriptName]: (props) => <MultiImages1280Front {...props} />,
    [YnetPlusPromotion.siteScriptName]: (props) => <YnetPlusPromotion {...props} />,
    [SiteArticleContact.siteScriptName]: (props) => <SiteArticleContact {...props} />,
    [Accordion.siteScriptName]: (props) => <Accordion {...props} />,
    [YnetShops1280Gallery.siteScriptName]: (props) => <YnetShops1280Gallery {...props} />,
    [ArticleImageSlider.siteScriptName]: (props) => <ArticleImageSlider {...props} />,
    [RedMailSiteComponentaWrapper.siteScriptName]: (props) => <RedMailSiteComponentaWrapper {...props} />,
    [ArticleImagePriceTagPointsComponent.siteScriptName]: (props) => <ArticleImagePriceTagPointsComponent {...props} />,
    [AppArticleContact.siteScriptName]: (props) => <AppArticleContact {...props} />,
    [CommertialTeaserFront.siteScriptName]: (props) => <CommertialTeaserFront {...props} />,
    [CommertialTeaserMobile.siteScriptName]: (props) => <CommertialTeaserMobile {...props} />,
    [SiteArticleBottomShareCtech.siteScriptName]: (props) => <SiteArticleBottomShareCtech {...props} />,
    [SiteCategoryHeaderComponenta.siteScriptName]: (props) => <SiteCategoryHeaderComponenta {...props} />,
    [CtechHeaderMenu.siteScriptName]: (props) => <CtechHeaderMenu {...props} />,
    [WeatherMultiArticleItem.siteScriptName]: (props) => <WeatherMultiArticleItem {...props} />,
    [SiteMobileCtechHeaderComponenta.siteScriptName]: (props) => <SiteMobileCtechHeaderComponenta {...props} />,
    [OneVideoGallery.siteScriptName]: (props) => <OneVideoGallery {...props} />,
    [SiteCtechWideBuzzComponenta.siteScriptName]: (props) => <SiteCtechWideBuzzComponenta {...props} />,
    [PplusMobileHeader.siteScriptName]: (props) => <PplusMobileHeader {...props} />,
    [PplusShareMenu.siteScriptName]: (props) => <PplusShareMenu {...props} />,
    [SiteCtechTwentyFourSevenWideComponenta.siteScriptName]: (props) => <SiteCtechTwentyFourSevenWideComponenta {...props} />,
    [MobileArticleImagesGallery.siteScriptName]: (props) => <MobileArticleImagesGallery {...props} />,
    [SitePplusLoadMoreComponenta.siteScriptName]: (props) => <SitePplusLoadMoreComponenta {...props} />,
    [CartoonArchiveComponenta.siteScriptName]: (props) => <CartoonArchiveComponenta {...props} />,
    [CartoonArchiveComponentaMobile.siteScriptName]: (props) => <CartoonArchiveComponentaMobile {...props} />,
    [MobileYPlusTopStory.siteScriptName]: (props) => <MobileYPlusTopStory {...props} />,
    [YplusMultiImagesFront.siteScriptName]: (props) => <YplusMultiImagesFront {...props} />,
    [ArticleMobileHeaderShareComponent.siteScriptName]: (props) => <ArticleMobileHeaderShareComponent {...props} />,
    [SiteMediaMobileCarousel.siteScriptName]: (props) => <SiteMediaMobileCarousel {...props} />,
    [MutamMarketingSign.siteScriptName]: (props) => <MutamMarketingSign {...props} />,
    [SiteMutamYnetHeaderComponenta.siteScriptName]: (props) => <SiteMutamYnetHeaderComponenta {...props} />,
    [SiteArticleVerticalSocialShare.siteScriptName]: (props) => <SiteArticleVerticalSocialShare {...props} />,
    [NewsLetterSignUpPopUp.siteScriptName]: (props) => <NewsLetterSignUpPopUp {...props} />,
    [CalcalistPianoLoginAndShare.siteScriptName]: (props) => <CalcalistPianoLoginAndShare {...props} />,
    [PremiumMutamHeader.siteScriptName]: (props) => <PremiumMutamHeader {...props} />,
    [PromoYplusBtn.siteScriptName]: (props) => <PromoYplusBtn {...props} />,
    [SiteYnetNewsHeaderComponenta.siteScriptName]: (props) => <SiteYnetNewsHeaderComponenta {...props} />,
    [SiteMutamYnetNewsHeaderComponenta.siteScriptName]: (props) => <SiteMutamYnetNewsHeaderComponenta {...props} />,
    [RadioHomepagePlayerComponenta.siteScriptName]: (props) => <RadioHomepagePlayerComponenta {...props} />,
    [GeneralCommercialTeaserFront.siteScriptName]: (props) => <GeneralCommercialTeaserFront {...props} />,
    [RadioScheduleComponent.siteScriptName]: (props) => <RadioScheduleComponent {...props} />,
    [PodcastPlayer.siteScriptName]: (props) => <PodcastPlayer {...props} />,
    [RadioNewsflashPlayer.siteScriptName]: (props) => <RadioNewsflashPlayer {...props} />,
    [SiteArticleRecipeRatingComponenta.siteScriptName]: (props) => <SiteArticleRecipeRatingComponenta {...props} />,
    [CalcalistMobileNavigationComponenta.siteScriptName]: (props) => <CalcalistMobileNavigationComponenta {...props} />,
    [IngredientsSection.siteScriptName]: (props) => <IngredientsSection {...props} />,
    [TimerComponenta.siteScriptName]: (props) => <TimerComponenta {...props} />,
    [SiteVideoArchiveComponenta.siteScriptName]: (props) => <SiteVideoArchiveComponenta {...props} />,
    [CalcalistNewsLetterComponenta.siteScriptName]: (props) => <CalcalistNewsLetterComponenta {...props} />,
    [CalcalistNewsLetterNativeComponenta.siteScriptName]: (props) => <CalcalistNewsLetterNativeComponenta {...props} />,
    [FloatingVideoLivePlayer.siteScriptName]: (props) => <FloatingVideoLivePlayer {...props} />,
    [AppRadioArticleLivePlayer.siteScriptName]: (props) => <AppRadioArticleLivePlayer {...props} />,
    [SitePhotoArchiveComponenta.siteScriptName]: (props) => <SitePhotoArchiveComponenta {...props} />,
    [GoogleSearchComponenta.siteScriptName]: (props) => <GoogleSearchComponenta {...props} />,
    [AudioPodcastPlayer.siteScriptName]: (props) => <AudioPodcastPlayer {...props} />,
    [TwentyFourSeven1280Componenta.siteScriptName]: (props) => <TwentyFourSeven1280Componenta {...props} />,
    [PodcastCategoryComponenta.siteScriptName]: (props) => <PodcastCategoryComponenta {...props} />,
    [SiteMusafArchiveComponenta.siteScriptName]: (props) => <SiteMusafArchiveComponenta {...props} />,
    [ArticleNewsletterWrapper.siteScriptName]: (props) => <ArticleNewsletterWrapper {...props} />,
    [CalcalistPodcastShareMenu.siteScriptName]: (props) => <CalcalistPodcastShareMenu {...props} />,
    [SignUpForPodcast.siteScriptName]: (props) => <SignUpForPodcast {...props} />,
    [PodcastArchiveComponenta.siteScriptName]: (props) => <PodcastArchiveComponenta {...props} />,
    [CalcalistDetachedPlayer.siteScriptName]: (props) => <CalcalistDetachedPlayer {...props} />,
    [CalcalistPodcastPlayer.siteScriptName]: (props) => <CalcalistPodcastPlayer {...props} />,
    [PodcastPlayerControlsComponent.siteScriptName]: (props) => <PodcastPlayerControlsComponent {...props} />,
    [YplusAccessibiltyButton.siteScriptName]: (props) => <YplusAccessibiltyButton {...props} />,
    [NewsFlashAndTwentyFourSevenMobileComponenta.siteScriptName]: (props) => <NewsFlashAndTwentyFourSevenMobileComponenta {...props} />,
    [YplusNotStickyMenu.siteScriptName]: (props) => <YplusNotStickyMenu {...props} />,
    [YnetLiveComponenta.siteScriptName]: (props) => <YnetLiveComponenta {...props} />,
    [TickerAutoDesktopView.siteScriptName]: (props) => <TickerAutoDesktopView {...props} />,
    [LiveVideoPlayerMutamComponenta.siteScriptName]: (props) => <LiveVideoPlayerMutamComponenta {...props} />,
    [BlogPostsSorter.siteScriptName]: (props) => <BlogPostsSorter {...props} />,
    [ArticleBlogsReadMoreHandler.siteScriptName]: (props) => <ArticleBlogsReadMoreHandler {...props} />,
    [CalcalistMutamShareMenu.siteScriptName]: (props) => <CalcalistMutamShareMenu {...props} />,
    [BlogsSpotimScriptInjector.siteScriptName]: (props) => <BlogsSpotimScriptInjector {...props} />,
    [HotArticleSlotListMutam.siteScriptName]: (props) => <HotArticleSlotListMutam {...props} />,
    [VideoGalleryVerticalComponenta.siteScriptName]: (props) => <VideoGalleryVerticalComponenta {...props} />,
    [VideoGalleryVerticalMutamComponenta.siteScriptName]: (props) => <VideoGalleryVerticalMutamComponenta {...props} />,
    [StockPopUp.siteScriptName]: (props) => <StockPopUp {...props} />,
    [VideoPageMain.siteScriptName]: (props) => <VideoPageMain {...props} />,
    [VideoMainPageMediaShare.siteScriptName]: (props) => <VideoMainPageMediaShare {...props} />,
    [ArticleMutamHeaderShareComponent.siteScriptName]: (props) => <ArticleMutamHeaderShareComponent {...props} />,
    [StockPopUpMobile.siteScriptName]: (props) => <StockPopUpMobile {...props} />,
    [CaricaturistMobileComponenta.siteScriptName]: (props) => <CaricaturistMobileComponenta {...props} />,
    [CalcalistWideBuzzMobileComponenta.siteScriptName]: (props) => <CalcalistWideBuzzMobileComponenta {...props} />,
    [SiteArticleBlogHeaderComponenta.siteScriptName]: (props) => <SiteArticleBlogHeaderComponenta {...props} />,
    [ArticleImageGalleryMutamCalcalist.siteScriptName]: (props) => <ArticleImageGalleryMutamCalcalist {...props} />,
    [SiteMediaMobileWrapper.siteScriptName]: (props) => <SiteMediaMobileWrapper {...props} />,
    [CalcalistWidePhotoMobileComponenta.siteScriptName]: (props) => <CalcalistWidePhotoMobileComponenta {...props} />,
    [CategoryHeaderMutam.siteScriptName]: (props) => <CategoryHeaderMutam {...props} />,
    [RadionasVideoGalleryVerticalMutamComponenta.siteScriptName]: (props) => <RadionasVideoGalleryVerticalMutamComponenta {...props} />,
    [RadionasVideoGalleryVerticalComponenta.siteScriptName]: (props) => <RadionasVideoGalleryVerticalComponenta {...props} />,
    [VideoGalleryVerticalAutoComponenta.siteScriptName]: (props) => <VideoGalleryVerticalAutoComponenta {...props} />,
    [VideoGalleryVerticalAutoMutamComponenta.siteScriptName]: (props) => <VideoGalleryVerticalAutoMutamComponenta {...props} />,
    [MagazineGalleryImages.siteScriptName]: (props) => <MagazineGalleryImages {...props} />,
    [CategoryHeaderMutam.siteScriptName]: (props) => <CategoryHeaderMutam {...props} />,
    [CalcalistTwentyFourSevenWideMobile.siteScriptName]: (props) => <CalcalistTwentyFourSevenWideMobile {...props} />,
    [SiteRadionasHeaderComponenta.siteScriptName]: (props) => <SiteRadionasHeaderComponenta {...props} />,
    [SiteMutamRadionasHeaderComponenta.siteScriptName]: (props) => <SiteMutamRadionasHeaderComponenta {...props} />,
    [RadionasVideoLiveMutamComponenta.siteScriptName]: (props) => <RadionasVideoLiveMutamComponenta {...props} />,
    [ArticleMagazineChapterMenu.siteScriptName]: (props) => <ArticleMagazineChapterMenu {...props} />,
    [RadionasAudioPlayer.siteScriptName]: (props) => <RadionasAudioPlayer {...props} />,
    [NasRadioScheduleComponent.siteScriptName]: (props) => <NasRadioScheduleComponent {...props} />,
    [NasRadioHomepageComponent.siteScriptName]: (props) => <NasRadioHomepageComponent {...props} />,
    [NasRadioHomepageTeaser.siteScriptName]: (props) => <NasRadioHomepageTeaser {...props} />,
    [MultiImagesRadionasFront.siteScriptName]: (props) => <MultiImagesRadionasFront {...props} />,
    [MultiImagesRadionasFrontMobile.siteScriptName]: (props) => <MultiImagesRadionasFrontMobile {...props} />,
    [RadionasNewsFlashHomepageComponent.siteScriptName]: (props) => <RadionasNewsFlashHomepageComponent {...props} />,
    [FrontCopyButton.siteScriptName]: (props) => <FrontCopyButton {...props} />,
    [NewsFlashListWrapper.siteScriptName]: (props) => <NewsFlashListWrapper {...props} />,
    [RadionasVideoPageMain.siteScriptName]: (props) => <RadionasVideoPageMain {...props} />,
    [ArticleImageSliderGalleryComponenta.siteScriptName]: (props) => <ArticleImageSliderGalleryComponenta {...props} />,
    [ArticleCommercialVideoPlayer.siteScriptName]: (props) => <ArticleCommercialVideoPlayer {...props} />,
    [GeneralCommercialTeaserMobile.siteScriptName]: (props) => <GeneralCommercialTeaserMobile {...props} />,
    [CalcalistVideoGalleryVerticalComponenta.siteScriptName]: (props) => <CalcalistVideoGalleryVerticalComponenta {...props} />,
    [CalcalistVideoGalleryVerticalMutamComponenta.siteScriptName]: (props) => <CalcalistVideoGalleryVerticalMutamComponenta {...props} />,
    [SideVideoMutamSlots.siteScriptName]: (props) => <SideVideoMutamSlots {...props} />,
    [ImageAndCaptionMutamItem.siteScriptName]: (props) => <ImageAndCaptionMutamItem {...props} />,
    [YnetNewsComponenta.siteScriptName]: (props) => <YnetNewsComponenta {...props} />,
    [MultiMagazineYplusFront.siteScriptName]: (props) => <MultiMagazineYplusFront {...props} />,
    [MultiImagesYplusFrontMobile.siteScriptName]: (props) => <MultiImagesYplusFrontMobile {...props} />,
    [DynamicVideoGalleryVerticalComponenta.siteScriptName]: (props) => <DynamicVideoGalleryVerticalComponenta {...props} />,
    [DynamicVideoGalleryVerticalMutamComponenta.siteScriptName]: (props) => <DynamicVideoGalleryVerticalMutamComponenta {...props} />,
    [StoriesAutoComponenta.siteScriptName]: (props) => <StoriesAutoComponenta {...props} />,
    [StoriesAutoMobileComponenta.siteScriptName]: (props) => <StoriesAutoMobileComponenta {...props} />,
    [ArticleStoryComponent.siteScriptName]: (props) => <ArticleStoryComponent {...props} />,
    [MutamVideoItem.siteScriptName]: (props) => <MutamVideoItem {...props} />,
    [MultiArticlesSectionFront.siteScriptName]: (props) => <MultiArticlesSectionFront {...props} />,
    [YplusWithPaperUpperSection.siteScriptName]: (props) => <YplusWithPaperUpperSection {...props} />,
    [LiveVideoPlayerMutamComponentaEng.siteScriptName]: (props) => <LiveVideoPlayerMutamComponentaEng {...props} />,
    [SiteMagazineHeaderComponenta.siteScriptName]: (props) => <SiteMagazineHeaderComponenta {...props} />,
    [SiteMagazineHeaderMutamComponenta.siteScriptName]: (props) => <SiteMagazineHeaderMutamComponenta {...props} />,
    [AccessebilityButton.siteScriptName]: (props) => <AccessebilityButton {...props} />,
    [MagazineVideoGalleryVerticalComponenta.siteScriptName]: (props) => <MagazineVideoGalleryVerticalComponenta {...props} />,
    [MagazineVideoGalleryVerticalMutamComponenta.siteScriptName]: (props) => <MagazineVideoGalleryVerticalMutamComponenta {...props} />,

    //-- REMEMBER: common frontend code goes in the start of the list!!!!
    //-- end list

    ["test"]: (props) => <div>{props}</div>
}

window['YITSiteWidgets'] = new YITSiteWidgets();